








import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import FormBase from '@/components/helper/form-base.vue';
import ModalView from '@/components/helper/modal-view.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import User from '@/store/entities/admin/user';

@Component({
    components: { FormBase, ModalView },
    data() {
        return {
            modalShow: false
        }
    },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.user = Util.extend(true, {}, this.$store.state.user.editModel);
                obj.initForm();
            } else
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            }
        }
    }
})

export default class UserForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    user: User = new User();
    formBase: boolean = true;
    modalSetting: any = {
        entity: "user",
        title: 'Usuarios',
        width: "700px",
        showForm: false,
    };        

    
    items = [
        this.gItem({ type: 'text', prop: 'first_name', label: 'Nombre', required: true, size: 6 }),
        this.gItem({ type: 'text', prop: 'last_name', label: 'Apellidos', size: 6 }),
        this.gItem({ type: 'text', prop: 'username', label: 'Usuario', required: true, size: 6 }),
        this.gItem({ type: 'password', prop: 'password', label: 'Password', size: 6, hint: 'Minimo 8 caracteres', persistentHint: true }),
        this.gItem({ type: 'email', prop: 'email', label: 'Email', required: true, size: 8 }),
        this.gItem({ type: 'active', size: 4 }),
        this.gItem({ type: 'divider', subheader: 'Asociaciones' }),
        this.gItem({ type: 'object', prop: 'roles', label: 'Roles', multiple: true, size: 12, entity: 'role', required: true, returnObject: true, onchange: this.changeRole }),            
        this.gItem({ type: 'object', prop: 'leaders', label: 'Jefes', entity: 'user', itemText: 'full_name', size: 12, multiple: true, filterParam: 'is_leader', filterVal: [true] }),
        this.gItem({ type: 'object', prop: 'default_tab_id', label: 'Menu Por Defecto', entity: 'tab', size: 6, chips: true }),
        this.gItem({ type: 'spacer', size: 6 }),
    ]

    initForm() {
        this.setItem(this.items, 'username', 'disabled', false);
        this.setItem(this.items, 'roles', 'disabled', false);
        this.setItem(this.items, 'active', 'disabled', false);
        if (this.$store.state.user.editModel.id == 1) {
            this.setItem(this.items, 'username', 'disabled', true);
            this.setItem(this.items, 'roles', 'disabled', true);
            this.setItem(this.items, 'active', 'disabled', true);
        }

        this.changeRole(this.user.roles);   
    }

    get roleCommercial() {
        return abp.setting.get('DEFAULT_COMMERCIAL_ROL');
    }

    changeRole(roles) {
        this.setItem(this.items, 'leaders', 'visible', false);

        if (roles == null) return;

        if (roles.filter(item => { return item.id == this.roleCommercial }).length > 0) {
            this.setItem(this.items, 'leaders', 'visible', true);
        }
    }

    async save() {
        if (this.$refs.entityForm != undefined && (this.$refs.entityForm as any).validate()) {
            let model = Util.extend(true, {}, this.user);
            model.roles = [];
            this.user.roles.forEach(item => {
                model.roles.push(item.name);
            });
            
            await this.$store.dispatch({
                type: 'user/' + (this.user.id > 0 ? 'update' : 'create'),
                data: model
            });
            (this.$refs.entityForm as any).reset();
            this.$emit('save-success');
            this.$emit('input', false);
            Util.abp.notify.success(this, 'Guardado Correctamente');
        }
    }

    cancel() {
        this.$emit('input', false);
    }

    async mounted() {
    }

    async created() {
    }
}
